define("sc-portal/pods/matter/pdf-invoice/output/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B7BVovYu",
    "block": "{\"symbols\":[],\"statements\":[[5,\"sc-container\",[[13,\"class\",\"mt-5 text-center\"]],[[\"@width\"],[\"fluid\"]],{\"statements\":[[0,\"\\n    \"],[7,\"h4\"],[11,\"class\",\"mr-1\"],[9],[0,\"\\n        \"],[1,[29,\"fa-icon\",[\"spinner\"],[[\"spin\"],[true]]],false],[0,\"\\n        The download will begin shortly...\\n    \"],[10],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"\\n        If the download does not start, please\\n        \"],[7,\"a\"],[11,\"href\",\"#\"],[9],[0,\"\\n            click here\\n        \"],[3,\"action\",[[24,0,[]],\"downloadReport\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sc-portal/pods/matter/pdf-invoice/output/template.hbs"
    }
  });

  _exports.default = _default;
});