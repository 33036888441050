define("sc-portal/router", ["exports", "ember-router-scroll", "sc-portal/config/environment"], function (_exports, _emberRouterScroll, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend(_emberRouterScroll.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    metrics: Ember.inject.service(),
    didTransition: function didTransition() {
      this._super.apply(this, arguments);

      this._trackPage();
    },
    _trackPage: function _trackPage() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        var page = _this.url;
        var title = _this.currentRouteName === undefined ? 'unknown' : _this.currentRouteName;

        _this.metrics.trackPage({
          page: page,
          title: title
        });
      });
    }
  });
  Router.map(function () {
    this.route('matters', {
      restNamespace: true
    }, function () {
      this.route('document-new');
    });
    this.route('matter', {
      restNamespace: true,
      path: 'matters/:matter_id'
    }, function () {
      this.route('dashboard');
      this.route('detail', function () {
        this.route('info');
      });
      this.route('new-report');
      this.route('report-browser');
      this.route('subjects', function () {
        this.route('list');
        this.route('box-list');
      });
      this.route('documents', function () {
        this.route('info', {
          path: 'info/:document_id'
        });
        this.route('edit', {
          path: 'edit/:document_id'
        });
      });
      this.route('pdf-invoice', {
        path: 'pdf-invoice/:invoice_id'
      }, function () {
        this.route('output', {
          path: '/output/:invoice_output_id'
        });
      });
    });
    this.route('auth', function () {
      this.route('login');
      this.route('logout');
      this.route('reminder');
      this.route('reset', {
        path: '/reset/:code'
      });
      this.route('activate', {
        path: '/activate/:code'
      });
      this.route('force-login');
      this.route('otp-request');
      this.route('otp-login', {
        path: '/otp-login/:code'
      });
    });
    this.route('privacy-policy');
    this.route('terms-of-use');
    this.route('shared-file', {
      path: '/download-document/:code'
    });
    this.route('shared-file', {
      path: '/shared-file/:code'
    });
    this.route('403'); // Catch routes not found

    this.route('404', {
      path: '*path'
    });
    this.route('change-password');
    this.route('support');
  });
  var _default = Router;
  _exports.default = _default;
});